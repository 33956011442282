/* eslint-disable react-hooks/rules-of-hooks */
import {
    Box,
    Button,
    Collapse,
    Divider,
    Flex,
    HStack,
    Icon,
    IconButton,
    Spacer,
    Text,
    useColorMode,
    useColorModeValue,
    useDisclosure,
    VStack,
  } from "@chakra-ui/react";
  import { IconDefinition } from "@fortawesome/free-brands-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import React, { useEffect } from "react";
  import { Link, useHistory, useLocation } from "react-router-dom";
  import { SidebarItems, theme } from "../../AppSettings";
  import { _Store } from "../store";
  //import { SidebarItems, theme } from "../../AppSettings";
  //import { _Store } from "../store";
  //import { logout } from "../utils/handleTokenValidity";
  import {Logo} from "./Logo";
  import { useSelector } from "react-redux";
  import { MoonIcon, SunIcon } from "@chakra-ui/icons";
  import { logout } from "../utils/handleTokenValidity";
  
  const Sidebar = () => {
    return (
      <VStack p="4" spacing="4" align="stretch">
        <Logo w={200} ></Logo>
  
        <Divider borderColor={"gray.600"}></Divider>
        <SidebarItems></SidebarItems>
        <Spacer></Spacer>
  
        <User></User>
      </VStack>
    );
  };
  
  const User: React.FC = () => {
    const history = useHistory();
  
    const { vfUserName, role } = useSelector((state: _Store) => state.userReducer);
  
    const onLogout = () => {
      logout();
      history.push("/login");
    };
  
    const { colorMode, toggleColorMode } = useColorMode();
    const onToggle = () => {
      sessionStorage.setItem("colorMode", colorMode === "light" ? "dark" : "light");
      toggleColorMode();
    };
    return (
      <Flex
        alignItems="center"
        justifyContent="flex-start"
        p="3"
        boxShadow={"md"}
        rounded={"md"}
        bg={useColorModeValue("gray.700", "gray.700")}
        textColor={useColorModeValue("gray.200", "gray.200")}
      >
        <Box ml="1" mr="1" flex="1">
          <HStack alignItems="center">
            <Box>
              <Text fontSize="md" fontWeight="bold" w="210px" isTruncated textColor={useColorModeValue("white", "white")}>
                {vfUserName}
              </Text>
              <Text fontSize="sm" fontWeight="semibold" isTruncated textColor={useColorModeValue("white", "white")}>
                {role}
              </Text>
            </Box>
          </HStack>
  
          <HStack mt="3" justifyContent="flex-end">
            <IconButton
              icon={colorMode === "light" ? <SunIcon /> : <MoonIcon />}
              aria-label="toggleColorMode"
              onClick={onToggle}
              size="xs"
              bg={"gray.600"}
              _hover={{ bg: "gray.500" }}
              _active={{
                bg: "gray.500",
              }}
              textColor={useColorModeValue("gray.200", "gray.200")}
            ></IconButton>
            <Button onClick={onLogout} colorScheme={theme.chakraColorScheme} size="xs">
              Çıkış
            </Button>
          </HStack>
        </Box>
      </Flex>
    );
  };
  
  export const SideBarItem: React.FC<{ to: string; icon: IconDefinition; title: string }> = (props) => {
    const location = useLocation();
  
    return (
      <Flex
        transition="all"
        transitionDuration={"0.3s"}
        transitionTimingFunction="cubic-bezier(.08,.52,.52,1)"
        boxShadow={"xs"}
        to={props.to}
        as={Link}
        bg={
          location.pathname.startsWith(props.to)
            ? useColorModeValue(`${theme.chakraColorScheme}.500`, `gray.500`)
            : useColorModeValue("gray.700", "gray.700")
        }
        p="3"
        pl="5"
        rounded="md"
        textAlign="left"
        textColor={
          location.pathname.startsWith(props.to)
            ? useColorModeValue("white", "white")
            : useColorModeValue("gray.200", "gray.200")
        }
        alignItems="center"
        marginTop={3}
        _hover={useColorModeValue({ bg: `${theme.chakraColorScheme}.400` }, { boxShadow: "outline" })}
      >
        <Box w="4">
          <Icon as={() => <FontAwesomeIcon icon={props.icon} />} />
        </Box>
        <Text display="block" isTruncated ml="3" fontWeight="bold" fontSize="lg">
          {props.title}
        </Text>
      </Flex>
    );
  };
  
  export const CollapsibleSideBarItem: React.FC<{
    to: string;
    icon: IconDefinition;
    title: string;
    subMenus: { path: string; icon: IconDefinition; key: string }[];
  }> = (props) => {
    const location = useLocation();
    const { isOpen, onToggle, onOpen, onClose } = useDisclosure();
  
    useEffect(() => {
      const isOpen = props.subMenus.some((d) => location.pathname.startsWith(d.path));
  
      if (isOpen) {
        onOpen();
        return;
      } else onClose();
    }, [location]);
    return (
      <Box
        userSelect="none"
        rounded="md"
        boxShadow={"5"}
        bg={"gray.700"}
        textAlign="left"
        textColor={useColorModeValue("gray.200", "gray.200")}
        alignItems="center"
      >
        <Box onClick={onToggle} px="4" py="3" textColor={"white"} cursor="pointer">
          <Text display="block" isTruncated fontWeight="bold" fontSize="lg">
            {props.title}
          </Text>
        </Box>
        <Collapse in={isOpen}>
          <VStack alignItems="stretch" spacing="2" px="2" mb="2">
            {props.subMenus.map((d) => (
              <Box
                key={`sub-side-bar-${d.key}`}
                transition="all"
                cursor="pointer"
                p="3"
                borderRadius="md"
                transitionDuration={"0.3s"}
                transitionTimingFunction="cubic-bezier(.08,.52,.52,1)"
                as={Link}
                to={d.path}
                textAlign="left"
                textColor={"white"}
                bg={location.pathname.startsWith(d.path) ? "gray.500" : "gray.600"}
                alignItems="center"
                _hover={{ bg: "gray.500" }}
              >
                <Text display="block" isTruncated fontWeight="bold" fontSize="md">
                  {d.key}
                </Text>
              </Box>
            ))}
          </VStack>
        </Collapse>
      </Box>
    );
  };
  
  export default Sidebar;
  