import * as React from "react";
import { ChakraProvider, theme } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import Router from "./__global/router/Router";
import { store } from "./__global/store";
import { Provider } from "react-redux";
export const App = () => {
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <Router></Router>
        </BrowserRouter>
      </ChakraProvider>
    </Provider>
  );
};
// export const App = () => (
//   <ChakraProvider theme={theme}>
//     <Flex>
//       <Router>
//         <Sidebar />

//         <Switch>
         
//           <Route path="/roles/create">
//             <CreateRolePage />
//           </Route>
//           <Route path="/roles">
//             <RoleListComponent />
//           </Route>
//           <Route path="/terms">
//             <List />
//           </Route>
//           <Route path="/terms/create">
//             <CreateTermPage />
//           </Route>
//           <Route path="/"></Route>
//         </Switch>
//       </Router>
//     </Flex>
//   </ChakraProvider>
// );
