import { ColorMode, extendTheme } from "@chakra-ui/react";
import { faXingSquare } from "@fortawesome/free-brands-svg-icons";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { meReducer, userReducer } from "./__global/core/slice";
import useUserInfo from "./__global/core/useUserInfo";
import { CollapsibleSideBarItem, SideBarItem } from "./__global/layout/Sidebar";
import { _Menu } from "./__global/types";

export const SidebarItems = () => {
  const { service, getUser } = useUserInfo();
  const history = useHistory();
  const location = useLocation();
  const [menuList, setMenuList] = useState([] as _Menu[]);
  const checkMenuPermission = (items: _Menu[]) => {
    let path = location.pathname.split("/")[1];
    if (path && !items.map((x) => x.url).includes(path)) {
      history.push("/");
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (service.serviceStatus === "loaded") {
       setMenuList(service.payload.userMenuItems);
       checkMenuPermission(service.payload.userMenuItems);
    }
  }, [service]);

  return (
    <>
      {service.serviceStatus === "loaded" &&
        menuList.map((x) => {
          var subMenuList: { path: string; icon: any; key: string }[] = [];
          for (const sub of x.subMenus) {
            subMenuList.push({
              key: sub.name,
              path: sub.url != null ? "/" + sub.url : "",
              icon: faXingSquare,
            });
          }

          return x.subMenus.length === 0 ? (
            <SideBarItem
              icon={faXingSquare}
              to={"/" + x.url}
              title={x.name}
              key={x.id}
            ></SideBarItem>
          ) : (
            <CollapsibleSideBarItem
              key={x.id}
              to={"/" + x.url}
              title={x.name}
              icon={faXingSquare}
              subMenus={subMenuList}
            ></CollapsibleSideBarItem>
          );
        })}
    </>
  );
};

export const theme = {
  primary: "#E53E3E",
  chakraColorScheme: "red",
};

export const colorMode: ColorMode = "light";
export const chakraUITheme = extendTheme({
  shadows: {
    outline: `0 0 0 3px ${theme.primary}`,
  },
  config: {
    initialColorMode: colorMode,
    useSystemColorMode: false,
  },
});

export const reducer = {
  meReducer,
  userReducer,
};
