import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { _AuthModel, _UserModel } from "../types";
import { loadState, saveState } from "../utils/sessionStorageService";

const initialState: _AuthModel = loadState() || null;
const userInitialState: _UserModel = {
  id: "",
  name: "",
  surname: "",
  userMenuItems: [],
  vfUserName: "",
  role: ""
};

const meSlice = createSlice({
  name: "me",
  initialState: initialState,
  reducers: {
    updateMe: (state, { payload }: PayloadAction<_AuthModel>) => {
      saveState(payload);
      return (state = payload);
    },
  },
});

const userSlice = createSlice({
    name: "user",
    initialState: userInitialState,
    reducers: {
        setUser: (state, { payload }: PayloadAction<_UserModel>) => {
        //saveState(payload);
        return (state = payload);
      },
    },
  });

export const meReducer = meSlice.reducer;
export const { updateMe } = meSlice.actions;


export const userReducer = userSlice.reducer;
export const { setUser } = userSlice.actions;