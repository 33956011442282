import loadable from "@loadable/component";
import React from "react";
import PrivateRoute from "./__global/router/PrivateRouter";

const TermListComponent = loadable(() => import("./modules/terms/pages/List"));
const TermCreateComponent = loadable(
  () => import("./modules/terms/pages/Create")
);
const TermEditComponent = loadable(() => import("./modules/terms/pages/Edit"));

const RoleListComponent = loadable(() => import("./modules/roles/pages/List"));
const RoleCreateComponent = loadable(
  () => import("./modules/roles/pages/Create")
);
const RoleEditComponent = loadable(() => import("./modules/roles/pages/Edit"));

const UserListComponent = loadable(() => import("./modules/users/pages/List"));
const UserCreateComponent = loadable(
  () => import("./modules/users/pages/Create")
);
const UserEditComponent = loadable(() => import("./modules/users/pages/Edit"));

const ReportListComponent = loadable(() => import("./modules/reports/pages/List"));


const CompetitiveTermListComponent = loadable(() => import("./modules/competitive-terms/pages/List"));
const CompetitiveTermCreateComponent = loadable(() => import("./modules/competitive-terms/pages/Create"));
const CompetitiveTermEditComponent = loadable(() => import("./modules/competitive-terms/pages/Edit"));

const CompetitiveTermReportListComponent = loadable(() => import("./modules/competitive-reports/pages/List"));



export const routes = (
  <>
    <PrivateRoute exact path="/users" component={UserListComponent} />
    <PrivateRoute exact path="/users/create" component={UserCreateComponent} />
    <PrivateRoute exact path="/users/edit/:id" component={UserEditComponent} />

    <PrivateRoute exact path="/roles" component={RoleListComponent} />
    <PrivateRoute exact path="/roles/create" component={RoleCreateComponent} />
    <PrivateRoute exact path="/roles/edit/:id" component={RoleEditComponent} />

    <PrivateRoute exact path="/terms" component={TermListComponent} />
    <PrivateRoute exact path="/terms/create" component={TermCreateComponent} />
    <PrivateRoute exact path="/terms/edit/:id" component={TermEditComponent} />

    <PrivateRoute exact path="/reports" component={ReportListComponent} />

    <PrivateRoute exact path="/competitive-terms" component={CompetitiveTermListComponent} />
    <PrivateRoute exact path="/competitive-terms/create" component={CompetitiveTermCreateComponent} />
    <PrivateRoute exact path="/competitive-terms/edit/:id" component={CompetitiveTermEditComponent} />

    <PrivateRoute exact path="/competitive-reports" component={CompetitiveTermReportListComponent} />


  </>
);
