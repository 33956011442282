import { AxiosResponse } from "axios";
import { useState } from "react";
import { _Service, _ServiceLoaded, _UserModel } from "../types";
import { sessionStorageService } from "../utils/sessionStorageService";
import { useDispatch } from "react-redux";
import { setUser } from "./slice";
import panelApi from "../utils/panelApi";

const useUserInfo = () => {
  const [service, setService] = useState<_Service<_UserModel>>({ serviceStatus: "idle" });

  const dispatch = useDispatch();
  const getUser = () =>
    new Promise<_Service<_UserModel>>(async (resolve) => {
      try {
        setService({ serviceStatus: "loading" });

        //const response: AxiosResponse<_ServiceLoaded<_UserModel>> = await axios.post(config.API_URL + "panel/auth/email", values);
        const response: AxiosResponse<_ServiceLoaded<_UserModel>> = await panelApi.get(
          "api/account/info"
        );

        let value: _ServiceLoaded<_UserModel> = {
          ...response.data,
          serviceStatus: "loaded",
        };
        if (value.processStatus === "Success") {
            
         dispatch(setUser(response.data.payload));
        }

        resolve(value);
        setService(value);
      } catch (error) {
        sessionStorageService.clearToken();
        setService({ serviceStatus: "failed" });
        resolve({ serviceStatus: "failed" });
      }
    });

  return { service, getUser };
};

export default useUserInfo;