//import axios from "axios";
import jwt_decode from "jwt-decode";
import { clearState, sessionStorageService } from "./sessionStorageService";

const handleTokenValidity = () =>
  new Promise(async (resolve, reject) => {
    const ACCESS_TOKEN = sessionStorageService.getAccessToken();

    if (ACCESS_TOKEN == null) {
      logout();
      reject(false);
    } else {
      const isTokenValid = checkAccessTokenIsValid();

      if (!isTokenValid) {
        logout();
        reject(false);
      }
      resolve(true)
    }
  });

const checkAccessTokenIsValid = (): boolean => {

  const ACCESS_TOKEN = sessionStorageService.getAccessToken();
  if (ACCESS_TOKEN) {
    try {
      const decoded: { exp: string; role: string } = jwt_decode(ACCESS_TOKEN);
      const exp = Number(decoded.exp);
      if (exp && Date.now() < exp * 1000) {
        return true;
      }
      logout();
      return false;
    } catch (_) {
      logout();
      return false;
    }
  }
  return false;
};
// const refreshAccessToken = () =>
//   new Promise<boolean>(async (resolve) => {
//     const refresh_token = Cookies.get("refresh_token");
//     const response = await axios.post("/auth/refresh_token", { refresh_token });
//
//     resolve(response.data.success as boolean);
//   });

export const logout = () => {
  sessionStorageService.clearToken();
  clearState();
};

export default handleTokenValidity;
