import React, { useEffect } from "react";
import { Route, RouteProps, useHistory } from "react-router-dom";
import handleTokenValidity from "../utils/handleTokenValidity";

const PrivateRoute: React.FC<RouteProps> = (props) => {
  useEffect(() => {
    init();
  }, [props.location]);
  const history = useHistory();
  const init = async () => {
    try {
      await handleTokenValidity();
    } catch (error) {
      history.push("/login");
    }
  };
  return <Route {...props}>{props.children}</Route>;
};

export default PrivateRoute;
