import { _AuthModel } from "../types";


export const sessionStorageService = (() => {
  function _setAccessToken(tokenObj: string) {
    sessionStorage.setItem("access_token", tokenObj);
  }

  function _getAccessToken() {
    return sessionStorage.getItem("access_token");
  }
  
  function _clearToken() {
    sessionStorage.removeItem("access_token");
  }

  return {
    setAccessToken: _setAccessToken,
    getAccessToken: _getAccessToken,
    clearToken: _clearToken,
  };
})();

export const loadState = (): _AuthModel => {
  try {
    const serializedState = sessionStorage.getItem("state");
    if (serializedState === null) {
      return null as any;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return null as any;
  }
};

export const saveState = (state: _AuthModel) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("state", serializedState);
  } catch (error) {
    console.error(error);
  }
};

export const clearState = () => {
  try {
    sessionStorage.removeItem("state");
  } catch (error) {
    console.error(error);
  }
};
