import loadable from "@loadable/component";
import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { routes } from "../../AppRouter";
import Layout from "../layout/Layout";
import handleTokenValidity from "../utils/handleTokenValidity";

//Login
const LoginComponent = loadable(() => import("../layout/Login"));

const Router = () => {
  const [ready, setReady] = useState(false);
  const history = useHistory();
  useEffect(() => {
    checkLoggedIn();
  }, []);

  const checkLoggedIn = async () => {
    try {
      await handleTokenValidity();
      setReady(true);
    } catch (error) {
      history.push("/login");
      setReady(true);
    }
  };
  
  if (ready)
    return (
      <Switch>
        <Route path="/login" exact component={LoginComponent} />
        <Layout>
          <Switch>{routes}</Switch>
        </Layout>
      </Switch>
    );
  return null;
};

export default Router;
