
import axios, { AxiosRequestConfig } from "axios";
import handleTokenValidity, { logout } from "./handleTokenValidity";
import { sessionStorageService } from "./sessionStorageService";


const panelApi = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/`,
  headers: {
    "Content-Type": "application/json",
  },
  params: {
    // culture: "tr",
  },
  // withCredentials: true,
});

panelApi.interceptors.request.use(async (config) => {
  await handleTokenValidity().then(() => {
    const ACCESS_TOKEN = sessionStorageService.getAccessToken();
    config.headers.common["Authorization"] = "Bearer " + ACCESS_TOKEN;
  });

  return config;
});
panelApi.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);
    if (error.response.status === 401) {
      logout();
      window.location.href = "/login";
    }
  },
);

export default panelApi;